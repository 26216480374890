(function () {
	'use strict';

	angular.module('dcApp').factory('DataBlocksService', DataBlockService);

	DataBlockService.$inject = [
		'$http',
		'$rootScope',
		'httpService',
		'ProjectDuplicateAdapter',
		'$q',
		'UrlService',
		'DateService',
	];

	function DataBlockService(
		$http,
		$rootScope,
		httpService,
		ProjectDuplicateAdapter,
		$q,
		UrlService,
		DateService
	) {
		var DataBlocksService = {};

		DataBlocksService.getFormules = function () {
			const deferred = $q.defer();
			const url = UrlService.generateUrl('CONSTANTS_FORMULAS');
			httpService.getRequest(url).then(function (response) {
				const sortedFormulas = response.data.formule.sort(function (a, b) {
					if (a.label < b.label) return -1;
					if (a.label > b.label) return 1;
					return 0;
				});
				const sortedConstants = response.data.constant.sort(function (a, b) {
					if (a.label < b.label) return -1;
					if (a.label > b.label) return 1;
					return 0;
				});
				deferred.resolve({
					formule: sortedFormulas,
					constant: sortedConstants,
				});
			});
			return deferred.promise;
		};

		DataBlocksService.getDataBlockColumnOrigin = function (datablockId, uuid) {
			const url = UrlService.generateUrl('DATABLOCK_GET_COLUMN_ORIGIN', {
				datablockId: datablockId,
				uuid: uuid,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.getDatablocks = function (
			excludePublic,
			excludeInactive
		) {
			const url = UrlService.generateUrl(
				'DATABLOCKS',
				{},
				{
					excludePublic: excludePublic ? excludePublic : false,
					excludeInactive: excludeInactive ? excludeInactive : false,
				}
			);
			return httpService.getRequest(url);
		};

		DataBlocksService.getExposedDataBlocks = function (projectId) {
			const deferred = $q.defer();
			const url = UrlService.generateUrl('DATABLOCKS_EXPOSED', {
				projectId: projectId,
			});
			httpService.getRequest(url).then(function (response) {
				deferred.resolve(
					ProjectDuplicateAdapter.getExposedDataBlocks(response.data)
				);
			});
			return deferred.promise;
		};

		DataBlocksService.getDuplicatedEndpoints = function (endpoints) {
			const url = UrlService.generateUrl(
				'DATABLOCKS_EXPOSE_DUPLICATED_ENDPOINTS'
			);
			return httpService.postRequest(url, endpoints);
		};

		DataBlocksService.deleteDataBlock = function (datablockId) {
			const url = UrlService.generateUrl('DATABLOCK', {
				datablockId: datablockId,
			});
			return $http.delete(url).then(function (response) {
				return response;
			});
		};

		DataBlocksService.deleteManyDataBlocks = function (ids) {
			const url = UrlService.generateUrl(
				'DATABLOCKS',
				{},
				{ datablockIds: ids }
			);
			return $http.delete(url).then(function (response) {
				return response;
			});
		};

		DataBlocksService.getManyDataBlocks = function (ids) {
			const url = UrlService.generateUrl('DATABLOCKS_BY_ID');
			return httpService.postRequest(url, ids);
		};

		DataBlocksService.createDataBlock = function (datablock) {
			const url = UrlService.generateUrl('DATABLOCKS');
			return httpService.postRequest(url, datablock);
		};

		DataBlocksService.createDataBlockByUets = function (uetsIds) {
			const url = UrlService.generateUrl('DATABLOCKS_CREATE_BY_UETS');
			return httpService.postRequest(url, uetsIds);
		};

		DataBlocksService.editDataBlock = function (datablock) {
			const url = UrlService.generateUrl('DATABLOCKS');
			return httpService.putRequest(url, datablock, false);
		};

		DataBlocksService.isChanged = function (datablock) {
			const url = UrlService.generateUrl('DATABLOCK_IS_CHANGED');
			return httpService.putRequest(url, datablock, false);
		};

		DataBlocksService.getDataBlock = function (datablockId) {
			const url = UrlService.generateUrl('DATABLOCK', {
				datablockId: datablockId,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.getReadyToDuplicateDataBlock = function (datablockId) {
			const url = UrlService.generateUrl('DATABLOCK_DUPLICATE', {
				datablockId: datablockId,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.isDataBlock = function (datablockId) {
			const url = UrlService.generateUrl(
				'DATABLOCK_IS_DATABLOCK',
				{},
				{ datablockId: datablockId }
			);
			return httpService.getRequest(url);
		};

		DataBlocksService.getExportHistory = function () {
			const url = UrlService.generateUrl('DATABLOCKS_EXPORT_HISTORY_BY_ID');
			return httpService.getRequest(url);
		};

		DataBlocksService.getDataBlockColumns = function (datablockId) {
			const url = UrlService.generateUrl(
				'DATABLOCK_COLUMNS',
				{},
				{ datablockId: datablockId }
			);
			return httpService.getRequest(url);
		};

		DataBlocksService.getReferencedDatablocks = function (datablockId) {
			const url = UrlService.generateUrl(
				'DATABLOCK_REFERENCED',
				{},
				{
					datablockId: datablockId,
				}
			);
			return httpService.getRequest(url);
		};

		DataBlocksService.getUsedEntitesAndDataBlocks = function (datablockId) {
			const url = UrlService.generateUrl('DATABLOCK_USED_ENTITES', {
				datablockId: datablockId,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.findUsedEntitiesAndDataBlocksListByIds = function (
			datablockId,
			referencedElementsIds
		) {
			const url = UrlService.generateUrl('DATABLOCK_USED_ENTITES_COUNT', {
				datablockId: datablockId,
				referencedElementsIds: referencedElementsIds,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.getLinkedHdElements = function (datablockId) {
			const url = UrlService.generateUrl('DATABLOCK_LINKED_HD_ELEMENTS', {
				datablockId: datablockId,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.getUpdates = function (datablockId, newGrammar) {
			const url = UrlService.generateUrl('DATABLOCK_UPDATES', {
				datablockId: datablockId,
			});
			return httpService.postRequest(url, newGrammar);
		};

		DataBlocksService.getLastPersistanceDate = function (datablockCode) {
			const url = UrlService.generateUrl(
				'DATABLOCK_LAST_PERSIST_DATE',
				{},
				{ datablockCode: datablockCode }
			);
			return httpService.getRequest(url);
		};

		DataBlocksService.columnIsUsedInDataBlockLinks = function (
			datablockId,
			colUuid
		) {
			const url = UrlService.generateUrl(
				'DATABLOCK_COL_IS_LINKED',
				{
					datablockId: datablockId,
				},
				{
					colUuid: colUuid,
				}
			);
			return httpService.getRequest(url);
		};

		DataBlocksService.dbIsUsedInDataBlockLinks = function (datablockId) {
			const url = UrlService.generateUrl('DATABLOCK_IS_LINKED', {
				datablockId: datablockId,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.getDBBuilderColumns = function (grammar) {
			const url = UrlService.generateUrl('DB_BUILDER_COLUMNS');
			return httpService.postRequest(url, grammar);
		};

		DataBlocksService.getImpact = function (steps) {
			const url = UrlService.generateUrl('DATABLOCK_GET_STEP_INPACT');
			return httpService.postRequest(url, steps);
		};

		DataBlocksService.findTopValues = function (
			text,
			searchMode,
			sortMode,
			column,
			columnType,
			grammar,
			limit
		) {
			const url = UrlService.generateUrl(
				'DATABLOCK_TOP_VALUES',
				{},
				{
					searchMode: searchMode,
					sort: sortMode ? sortMode : '',
					column: column,
					columnType: columnType,
					limit: limit,
					text: text,
				}
			);
			return httpService.postRequest(url, grammar);
		};

		DataBlocksService.findDistinctValues = function (
			column,
			columnType,
			grammar,
			sort,
			limit
		) {
			const url = UrlService.generateUrl(
				'DATABLOCK_DISTINCT_VALUES',
				{},
				{
					column: column,
					columnType: columnType,
					limit: limit,
					sort: sort,
					useCache: $rootScope.jobCacheActive,
				}
			);
			return httpService.postRequest(url, grammar);
		};

		DataBlocksService.getCaracUsage = function (entiteId, caracId) {
			const url = UrlService.generateUrl('ENTITES_CARAC_USAGE', {
				id: entiteId,
				caracId: caracId,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.getDataBlockUsage = function (dbId) {
			const url = UrlService.generateUrl('DATABLOCKS_USAGE', { id: dbId });
			return httpService.getRequest(url);
		};

		DataBlocksService.getColumnCount = function (dbId) {
			const url = UrlService.generateUrl('DATABLOCK_COLUMN_COUNT', {
				datablockId: dbId,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.execData = function (
			config,
			elementId,
			parentId,
			clientId,
			noCache
		) {
			const url = UrlService.generateUrl(
				'DATABLOCK_EXEC',
				{},
				{
					elementId: elementId,
					clientId: clientId,
					useCache: $rootScope.jobCacheActive && !noCache,
				}
			);
			return httpService.postRequest(url, config);
		};

		DataBlocksService.getDataByJobId = function (jobKey) {
			const url = UrlService.generateUrl(
				'DATABLOCK_EXEC_RESULT',
				{},
				{
					jobKey: jobKey,
				}
			);
			return httpService.getRequest(url);
		};

		DataBlocksService.getCountUsingEntite = function (entiteId) {
			const url = UrlService.generateUrl('DATABLOCKS_ENTITY_USAGE', {
				id: entiteId,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.getExposeParams = function (datablockId) {
			const url = UrlService.generateUrl('DATABLOCK_EXPOSE_PARAMS', {
				datablockId: datablockId,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.editExposeParams = function (datablockId, params) {
			const url = UrlService.generateUrl('DATABLOCK_EXPOSE_PARAMS', {
				datablockId: datablockId,
			});
			return httpService.postRequest(url, params);
		};

		DataBlocksService.deleteExposeParams = function (datablockId) {
			const url = UrlService.generateUrl('DATABLOCK_EXPOSE_PARAMS', {
				datablockId: datablockId,
			});
			return $http.delete(url).then(function (response) {
				return response;
			});
		};

		DataBlocksService.exposeDatablock = function (datablockId, clientId) {
			const url = UrlService.generateUrl(
				'DATABLOCK_EXPOSE_EXEC',
				{
					datablockId: datablockId,
				},
				{ clientId: clientId }
			);
			return httpService.getRequest(url);
		};

		DataBlocksService.getCurrentExpose = function (datablockId) {
			const url = UrlService.generateUrl('DATABLOCK_EXPOSE_CURRENT', {
				datablockId: datablockId,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.enableExpose = function (datablockId) {
			const url = UrlService.generateUrl('DATABLOCK_EXPOSE_ENABLE', {
				datablockId: datablockId,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.disableExpose = function (datablockId) {
			const url = UrlService.generateUrl('DATABLOCK_EXPOSE_DISABLE', {
				datablockId: datablockId,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.getExposeHistory = function (datablockId) {
			const url = UrlService.generateUrl('DATABLOCK_EXPOSE_HISTORY', {
				datablockId: datablockId,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.deleteCurrentExpose = function (datablockId) {
			const url = UrlService.generateUrl('DATABLOCK_EXPOSE_DELETE', {
				datablockId: datablockId,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.getExposeInfo = function (dbName) {
			const url = UrlService.generateUrl('DATABLOCKS_DATA_INFO', {
				name: dbName,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.isExposed = function (datablockId) {
			const url = UrlService.generateUrl('DATABLOCK_EXPOSE_CHECK', {
				datablockId: datablockId,
			});
			return httpService.getRequest(url);
		};

		DataBlocksService.addExposePermissionFilter = function (
			datablockId,
			request
		) {
			const url = UrlService.generateUrl('DATABLOCK_EXPOSE_PARAMS_FILTERS', {
				datablockId: datablockId,
			});
			return httpService.postRequest(url, request);
		};

		DataBlocksService.editExposePermissionFilter = function (
			datablockId,
			filterId,
			request
		) {
			const url = UrlService.generateUrl(
				'DATABLOCK_EXPOSE_PARAMS_FILTERS_ONE',
				{
					datablockId: datablockId,
					filterId: filterId,
				}
			);
			return httpService.putRequest(url, request, false);
		};

		DataBlocksService.deleteExposePermissionFilter = function (
			datablockId,
			filterId
		) {
			const url = UrlService.generateUrl(
				'DATABLOCK_EXPOSE_PARAMS_FILTERS_ONE',
				{
					datablockId: datablockId,
					filterId: filterId,
				}
			);
			return $http.delete(url).then(function (response) {
				return response;
			});
		};

		DataBlocksService.exposeAttachPermissionToUsers = function (
			datablockId,
			filterId,
			request
		) {
			const url = UrlService.generateUrl(
				'DATABLOCK_EXPOSE_PARAMS_FILTERS_ONE',
				{
					datablockId: datablockId,
					filterId: filterId,
				}
			);
			return httpService.postRequest(url, request);
		};

		DataBlocksService.filterDatesToIsoDefaultTz = function (rules) {
			if (!rules) {
				return;
			}
			for (let j in rules) {
				if (rules[j].type === 'date' && rules[j].value) {
					if (rules[j].value.startsWith('[')) {
						const valueAsArray = JSON.parse(rules[j].value);
						if (valueAsArray) {
							const FormattedValues = valueAsArray
								.map((date) => DateService.getDateInIsoFormatInDefaultTz(date))
								.map((element) => `"${element}"`)
								.join(',');
							rules[j].value = '[' + FormattedValues + ']';
						}
					} else {
						rules[j].value = DateService.getDateInIsoFormatInDefaultTz(
							rules[j].value
						);
					}
				}
			}
		};

		return DataBlocksService;
	}
})();

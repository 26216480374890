//Provides utility functions for creating angular directives

let buildDirectiveReturnObject = function (
	scope,
	controller,
	templateUrl,
	linkFunction,
) {
	return {
		scope: scope,
		restrict: 'E',
		controller: controller,
		controllerAs: 'vm',
		bindToController: true,
		templateUrl: templateUrl,
		transclude: true,
		replace: true,
		link: linkFunction,
	};
};

//For popup directives
let initElementForDirective = function (element, attrs, onClose) {
	$(element).modal({
		show: false,
		keyboard: attrs.keyboard,
		backdrop: attrs.backdrop,
	});
	$(element).on('hidden.bs.modal', function (event) {
		if (event.target.id == element[0].id) {
			onClose();
		}
	});
};

let watchElementForDirective = function (
	element,
	scope,
	watchFunction,
	onInit,
	doNotShowModal
) {
	scope.$watch(watchFunction, function (value) {
		scope.vm.element = element;
		if (value) {
			onInit();
			if (!doNotShowModal) {
				$(element).modal({ backdrop: 'static', keyboard: false }, 'show');
			}
		} else {
			$(element).modal('hide');
		}
	});
};

let getHeaderRealReadableType = function (type, translateService) {
	switch (type) {
		case 'StringType':
			return translateService.getString('Chaîne de caractères');
		case 'BooleanType':
			return translateService.getString('Booléen');
		case 'TimestampType':
			return translateService.getString('Horodatage (Timestamp)');
		case 'DoubleType':
			return translateService.getString('Nombre double');
		case 'DecimalType':
			return translateService.getString('Nombre décimal');
		case 'LongType':
			return translateService.getString('Grand nombre entier');
		case 'IntegerType':
			return translateService.getString('Nombre entier (<2 Md)');
		case 'NullType':
			return translateService.getString('Null');
		case 'DateType':
			return translateService.getString('Date');
		case 'BinaryType':
			return translateService.getString('Fichier Binaire');
		default:
			return type;
	}
};

// Return enum label for a given label
function getCorrespondingEnumLabel(
	value,
	elements,
	isEnums,
	functionName,
	index,
	element
) {
	if (value == undefined || value == '') {
		return value != undefined ? value : '';
	}
	var label = value;
	var enums = undefined;
	if (isEnums) {
		enums = elements;
	} else {
		var functionElm = _.find(elements, function (elm) {
			return (
				elm.ident === functionName &&
				functionName != undefined &&
				elm.group != 'value'
			);
		});
		if (
			functionElm != undefined &&
			index != undefined &&
			functionElm.input &&
			functionElm.input[index]
		) {
			enums = functionElm.input[index].enums;
		}
	}
	if (enums != undefined) {
		for (var c = 0; c < enums.length; c++) {
			if (enums[c].value == value) {
				label = enums[c].label;
				break;
			}
		}
	}
	if (element != undefined) {
		element.enums = enums;
	}

	return label;
}

function getCorrespondingCaracLabel(ucaCode, columns) {
	var lib = ucaCode;
	for (var i = 0; i < columns.length; i++) {
		if (columns[i].value == ucaCode) {
			lib = columns[i].column_alias;
			break;
		}
	}

	return lib;
}

// Return column label for a given column name COLL'index'
function getCorrespondingColLabel(col, columns) {
	var labelIndex = col.replace('COLL', '');
	if (isNaN(labelIndex)) {
		let co = _.find(columns, function (elm) {
			return elm.uuid === col;
		});
		let lib = col;
		if (co && co.column_alias) {
			lib = co.column_alias;
		} else if (co && co.lib) {
			lib = co.lib;
		}
		return lib;
	} else {
		return columns[labelIndex] && columns[labelIndex].column_alias != undefined
			? columns[labelIndex].column_alias
			: col;
	}
}

function getFormulaDisplayName(functionName, styled) {
	const label = window.dcFormulasByIdentifer[functionName].label
		? window.dcFormulasByIdentifer[functionName].label
		: functionName;

	return !styled ? label : "<strong>" + label + "</strong>";
}

function getFormattedOpenParenthesis(formula, styled, formulaColor) {
	const label = window.dcFormulasByIdentifer[formula.function_name].label;
	return !styled ? "(" : "<strong><span style=\"font-size: large; color: " + formulaColor + "\"> ( </span></strong>";
}

function getFormattedClosedParenthesis(formula, styled, formulaColor) {
	return !styled ? ") " : "<strong><span style=\"font-size: large; color: " + formulaColor + "\"> )</span></strong>";
}

function selectColorByLevel(level) {
	const basicColors = ['#1153c7', '#9b5fe0', '#16a4d8', '#8bd346', '#efdf48', '#f9a52c', '#d64e12'];

	return basicColors[level % basicColors.length];
}

// Generate displayed formula from the formula object
function buildFormulaDisplayed(
	formula,
	caracMode,
	ruleTra,
	columns,
	formulaElements,
	styled,
	start,
	previousBuild,
	parentId,
	level
) {
	if (!formula) {
		return '';
	}
	let formulaColor;
	level = isEmpty(level) ? 0 : level;
	if (styled) {
		formulaColor = selectColorByLevel(level);
	}
	var id = formula.id;
	id = id != undefined ? id : 0;
	formula.id = id;
	formula.id = parentId != undefined ? parentId + '_' + formula.id : formula.id;
	formula.start_position = start != undefined ? start : 0;
	if (formula.type == 'constant') {
		var functionStr =
			previousBuild != undefined
				? previousBuild + formula.function_name + '()'
				: formula.function_name + '()';
	} else if (formula.type == 'function') {
		var functionStr =
			previousBuild != undefined
				? previousBuild +
				getFormulaDisplayName(formula.function_name, styled) + getFormattedOpenParenthesis(formula, styled, formulaColor)
				: getFormulaDisplayName(formula.function_name, styled) + getFormattedOpenParenthesis(formula, styled, formulaColor);
		for (var l = 0; l < formula.params.length; l++) {
			id = id + 1;
			formula.params[l].id = id;
			formula.params[l].id =
				parentId != undefined
					? parentId + '_' + formula.params[l].id
					: formula.params[l].id;
			formula.params[l].start_position = functionStr.length;
			if (
				formula.params[l].function_name == null ||
				formula.params[l].function_name == ''
			) {
				if (
					formula.params[l].type === 'value' ||
					formula.params[l].type === 'enum'
				) {
					var val = formula.params[l].value;
					if (formula.params[l].type === 'enum' && val != undefined) {
						let isEnums = !formulaElements;
						formulaElements = formulaElements
							? formulaElements
							: formula.params[l].enums;
						if (formulaElements) {
							val = getCorrespondingEnumLabel(
								val,
								formulaElements,
								isEnums,
								formula.function_name,
								l
							);
						} else {
							val = formula.params[l].label ? formula.params[l].label : val;
						}
					}
					val = val != undefined && val != null ? val : '';
					var paramV = val.startsWith("'") ? val : "'" + val;
					paramV = val.endsWith("'") ? paramV : paramV + "'";
					functionStr = functionStr + paramV;
				} else if (!formula.params[l].rule) {
					if (caracMode) {
						functionStr =
							functionStr +
							getCorrespondingCaracLabel(formula.params[l].value, columns);
					} else {
						functionStr =
							functionStr +
							getCorrespondingColLabel(formula.params[l].value, columns);
					}
				} else {
					let ruleTxt = new StringBuffer();
					getTextFromRule(ruleTxt, formula.params[l].rule, columns);
					if (styled) {
						functionStr = functionStr + "<span title=\"" + ruleTxt + "\"><i><u>" + ruleTra + "</u></i></span> [ " + ruleTxt + " ]";
					} else {
						functionStr = functionStr + ruleTra;
					}
				}
			} else {
				level = level + 1;
				functionStr = buildFormulaDisplayed(
					formula.params[l],
					caracMode,
					ruleTra,
					columns,
					formulaElements,
					styled,
					formula.params[l].start_position,
					functionStr,
					formula.params[l].id,
					level
				);
			}
			formula.params[l].end_position = functionStr.length;
			if (l < formula.params.length - 1) {
				functionStr = functionStr + (styled ? ', ' : ',');
			} else {
				functionStr = functionStr + getFormattedClosedParenthesis(formula, styled, formulaColor);
				formula.params[l].end_position = functionStr.length;
			}
		}
	} else {
		functionStr = functionStr + getFormattedClosedParenthesis(formula, styled, formulaColor);
	}

	formula.end_position = functionStr.length;

	return functionStr;
}
